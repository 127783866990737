import React, { useContext, useEffect } from "react";
import { Outlet, Link } from "react-router-dom";
import Email from "./Email";
import Certificate from "./CertificateImg";
import Contact from "./Contact";
import JournalList from "./JournalList";
import Whatsapp from "./Whatsapp";
import WhatsappSticky from "./WhatsappSticky";
import SearchBar from "./SearchBar";
import CoverPage from "./CoverPage";
import { GlobalContext } from "../context/GlobalContext";

export default function Root() {
  const { settings } = useContext(GlobalContext);
  // const [hide, setHide] = React.useState(false);

  // useEffect(() => {
  //   const journals = ['www.botanyjournals.com', 'www.entomologyjournals.com', 'www.fishjournals.com', 'www.foodsciencejournal.com', 'www.chemicaljournals.com', 'www.chemistryjournal.in', 'www.pharmacyjournal.net', 'www.pharmacyjournal.in', 'www.biologyjournal.in']
  //   journals.includes(settings.domain) ? setHide(false) : setHide(true)
  // }, [settings])


  return (
    <div>
      <div className="text-base" id="container">
        <div className="flex flex-col items-center justify-center w-full gap-12 lg:flex-row">
          <img className="w-48 h-48" src="/assets/images/logo.png" />
          <div className="flex flex-col justify-center w-full gap-12 text-center">
            <div
              className="justify-center p-5 text-3xl font-bold text-center lg:hidden text-blue-A"
              dangerouslySetInnerHTML={{
                __html: settings.formatted_journal_name,
              }}
            ></div>
            <div
              className="justify-center hidden px-24 text-4xl font-bold text-center lg:block lg:text-center text-blue-A"
              dangerouslySetInnerHTML={{
                __html: settings.websitename,
              }}
            ></div>
            <div className="flex flex-col text-xl font-bold text-blue-A lg:flex-row lg:gap-12">
              <div className="py-2 border-t-2 border-blue-A lg:border-none">
                <Link to="/">HOME</Link>
              </div>
              <div className="py-2 border-t-2 border-blue-A lg:border-none">
                <Link to="/board">EDITORIAL BOARD</Link>
              </div>
              <div className="py-2 border-t-2 border-blue-A lg:border-none">
                <Link to="/archives">ARCHIVES</Link>
              </div>
              <div className="py-2 border-t-2 border-blue-A lg:border-none">
                <Link to="/instructions">INSTRUCTIONS</Link>
              </div>
              <div className="py-2 border-t-2 border-blue-A lg:border-none">
                <Link to="/indexing">INDEXING</Link>
              </div>
              {/* {!hide && (<div className="py-2 border-t-2 border-blue-A lg:border-none">
                <Link to="/article">SUBMIT ONLINE</Link>
              </div>)} */}
              <div className="py-2 border-blue-A border-y-2 lg:border-none">
                <Link to="/contact">CONTACT US</Link>
              </div>
            </div>
          </div>
        </div>
        <img id="header-img" src="/assets/images/header.jpg" />
        <div id="body-container-desktop">
          <div className="sidebar">
            <Email />
            <Certificate />
            <Contact />
          </div>
          <div id="page-content">
            <Outlet />
          </div>
          <div className="sidebar">
            {/* <JournalList /> */}
            <Whatsapp />
            <SearchBar />
            <CoverPage />
          </div>
        </div>
        <div id="body-container-mobile">
          <WhatsappSticky />
          <SearchBar />
          <Outlet />
          <Email />
          <Contact />
          {/* <JournalList /> */}
          <CoverPage />
        </div>
      </div>
      <div id="footer">
        Copyright © {new Date().getFullYear()}. All Rights Reserved.
      </div>
    </div>
  );
}
