import React, { useContext, useEffect } from "react";
import Indexing from "./Indexing";
import { GlobalContext } from "../context/GlobalContext";

function htmlDecode(input) {
  var e = document.createElement('textarea');
  e.innerHTML = input;
  // handle case of empty input
  return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
}

export default function Home(props) {
  const { settings } = useContext(GlobalContext)

  useEffect(() => {
    document.title = settings.websitename
  }, [settings])

  console.log(settings)

  return (
    <>
      <div className="flex flex-col items-center w-full gap-6 text-3xl font-bold text-blue-A">
        {/* <div className="text-center">
          {settings && settings.issn && settings.issn.split(",")[0]}
          <br />
          {settings && settings.issn && settings.issn.split(",")[1]}
        </div> */}
        <div className="text-center">
          Indexed Journal<br />
          Refered Journal<br />
          Peer Reviewed Journal
        </div>
        <div className="text-center">
          We send published link, <br />certificate, coverpage in {settings.publishdays} days.
        </div>
        <div className="text-center">
          Starting Year : 2023 <br />
          Frequency : By Monthly <br />
          Subject : Multidisciplinary <br />
          Language : English <br />
          Format : Online
        </div>
      </div>
      <div className="pt-6" dangerouslySetInnerHTML={{ __html: typeof window !== 'undefined' ? htmlDecode(settings.home_content) : "" }}></div>
      {/* <div id="indexing-title">INDEXING</div>
      <Indexing /> */}
    </>
  );
}
