import React, { useContext, useEffect } from 'react'

const SubmitArticle = () => {

  return (
    <>
      <div>
        <div className='mt-5 text-3xl font-bold text-center text-white bg-blue-700 dark:text-white'>Submit Online</div>
        <div className='p-10 m-5 overflow-hidden bg-white border-2 border-gray-300' >
          <div className="grid grid-cols-4 gap-8">
            <div className="col-span-4 row-span-2">
              <div>Name</div>
              <input className={`w-full border-2 border-gray-300 rounded p-2 focus:outline-emerald-600 mb-4`} />
            </div>
          </div>
          <div className="grid grid-cols-4 gap-8">
            <div className="col-span-4 row-span-2">
              <div>Email</div>
              <input className={`w-full border-2 border-gray-300 rounded p-2 focus:outline-emerald-600 mb-4`} />
            </div>
          </div>
          <div className="grid grid-cols-4 gap-8">
            <div className="col-span-4 row-span-2">
              <div>
                <div>Country</div>
                <select
                  className={`w-full border-2 border-gray-300 rounded p-2 focus:outline-emerald-600 mb-4`}
                >
                  <option value="normal">Select Country</option>
                  <option value="India">India</option>
                  <option value="Afghanistan">Afghanistan</option>
                  <option value="Albania">Albania</option>
                  <option value="Algeria">Algeria</option>
                  <option value="Andorra">Andorra</option>
                  <option value="Angola">Angola</option>
                  <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                  <option value="Argentina">Argentina</option>
                  <option value="Armenia ">Armenia </option>
                  <option value="Australia">Australia</option>
                  <option value="Austria ">Austria </option>
                  <option value="Austrian Empire*">Austrian Empire</option>
                  <option value="Baden*">Baden</option>
                  <option value="Bahamas">Bahamas</option>
                  <option value="Bahrain">Bahrain</option>
                  <option value="Bangladesh">Bangladesh</option>
                  <option value="Barbados">Barbados</option>
                  <option value="Bavaria*">Bavaria</option>
                  <option value="Belarus">Belarus</option>
                  <option value="Belgium ">Belgium </option>
                  <option value="Belize">Belize</option>
                  <option value="Benin (Dahomey)">Benin</option>
                  <option value="Bolivia">Bolivia</option>
                  <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                  <option value="Botswana">Botswana</option>
                  <option value="Brazil">Brazil</option>
                  <option value="Brunei">Brunei </option>
                  <option value="Brunswick and Lüneburg">Brunswick and Lüneburg</option>
                  <option value="Bulgaria">Bulgaria </option>
                  <option value="Burkina Faso (Upper Volta)">Burkina Faso Upper Volta</option>
                  <option value="Burma">Burma </option>
                  <option value="Burundi">Burundi </option>
                  <option value="Cabo Verde">Cabo Verde</option>
                  <option value="Cambodia">Cambodia</option>
                  <option value="Cameroon">Cameroon</option>
                  <option value="Canada">Canada</option>
                  <option value="Cayman Islands">Cayman Islands</option>
                  <option value="Central African Republic">Central African Republic</option>
                  <option value="Central American Federation*">Central American Federation*</option>
                  <option value="Chad ">Chad </option>
                  <option value="Chile">Chile</option>
                  <option value="China ">China</option>
                  <option value="Colombia">Colombia</option>
                  <option value="Comoros ">Comoros</option>
                  <option value="Congo Free State, The*">Congo Free State</option>
                  <option value="Costa Rica">Costa Rica</option>
                  <option value="Cote d’Ivoire (Ivory Coast)">Cote d’Ivoire Ivory Coast </option>
                  <option value="Croatia ">Croatia</option>
                  <option value="Cuba">Cuba </option>
                  <option value="Cyprus ">Cyprus</option>
                  <option value="Czechia">Czechia </option>
                  <option value="Czechoslovakia">Czechoslovakia </option>
                  <option value="Democratic Republic of the Congo">Democratic Republic of the Congo </option>
                  <option value="Denmark ">Denmark</option>
                  <option value="Djibouti">Djibouti </option>
                  <option value="Dominica ">Dominica</option>
                  <option value="Dominican Republic">Dominican Republic </option>
                  <option value="Duchy of Parma">Duchy of Parma </option>
                  <option value="East Germany (German Democratic Republic)* ">East Germany</option>
                  <option value="Ecuador">Ecuador </option>
                  <option value="Egypt">Egypt </option>
                  <option value="El Salvador ">El Salvador </option>
                  <option value="Equatorial Guinea ">Equatorial Guinea</option>
                  <option value="Eritrea">Eritrea </option>
                  <option value="Estonia ">Estonia</option>
                  <option value="Eswatini ">Eswatini </option>
                  <option value="Ethiopia ">Ethiopia </option>
                  <option value="Fiji ">Fiji</option>
                  <option value="Finland ">Finland </option>
                  <option value="France ">France </option>
                  <option value="Gabon  ">Gabon </option>
                  <option value="Gambia">Gambia </option>
                  <option value="Georgia">Georgia </option>
                  <option value="Germany  "> Germany </option>
                  <option value="Ghana  ">Ghana </option>
                  <option value="Grand Duchy of Tuscany">Grand Duchy of Tuscany </option>
                  <option value="Greece ">Greece</option>
                  <option value="Grenada ">Grenada </option>
                  <option value="Guatemala ">Guatemala </option>
                  <option value="Guinea ">Guinea</option>
                  <option value="Guinea-Bissau ">Guinea-Bissau </option>
                  <option value="Guyana ">Guyana </option>
                  <option value="Haiti">Haiti</option>
                  <option value="Hanover*">Hanover*</option>
                  <option value="Hanseatic Republics*">Hanseatic Republics*</option>
                  <option value="Hawaii*">Hawaii*</option>
                  <option value="Hesse*">Hesse*</option>
                  <option value="Holy See">Holy See</option>
                  <option value="Honduras">Honduras</option>
                  <option value="Hungary">Hungary</option>
                  <option value="Iceland">Iceland</option>
                  <option value="Indonesia">Indonesia</option>
                  <option value="Iran">Iran</option>
                  <option value="Iraq">Iraq</option>
                  <option value="Ireland">Ireland</option>
                  <option value="Israel">Israel</option>
                  <option value="Italy">Italy</option>
                  <option value="Jamaica">Jamaica</option>
                  <option value="Japan">Japan</option>
                  <option value="Jordan">Jordan</option>
                  <option value="Kazakhstan">Kazakhstan</option>
                  <option value="Kenya">Kenya</option>
                  <option value="Kingdom of Serbia/Yugoslavia*">Kingdom of Serbia/Yugoslavia*</option>
                  <option value="Kiribati">Kiribati</option>
                  <option value="Korea">Korea</option>
                  <option value="Kosovo">Kosovo</option>
                  <option value="Kuwait">Kuwait</option>
                  <option value="Kyrgyzstan">Kyrgyzstan</option>
                  <option value="Laos">Laos</option>
                  <option value="Latvia">Latvia</option>
                  <option value="Lebanon">Lebanon</option>
                  <option value="Lesotho">Lesotho</option>
                  <option value="Lew Chew (Loochoo)*">Lew Chew (Loochoo)*</option>
                  <option value="Liberia">Liberia</option>
                  <option value="Libya">Libya</option>
                  <option value="Liechtenstein">Liechtenstein</option>
                  <option value="Lithuania">Lithuania</option>
                  <option value="Luxembourg">Luxembourg</option>
                  <option value="Madagascar">Madagascar</option>
                  <option value="Malawi">Malawi</option>
                  <option value="Malaysia">Malaysia</option>
                  <option value="Maldives">Maldives</option>
                  <option value="Mali">Mali</option>
                  <option value="Malta">Malta</option>
                  <option value="Marshall Islands">Marshall Islands</option>
                  <option value="Mauritania">Mauritania</option>
                  <option value="Mauritius">Mauritius</option>
                  <option value="Mecklenburg-Schwerin*">Mecklenburg-Schwerin*</option>
                  <option value="Mecklenburg-Strelitz*">Mecklenburg-Strelitz*</option>
                  <option value="Mexico">Mexico</option>
                  <option value="Micronesia">Micronesia</option>
                  <option value="Moldova">Moldova</option>
                  <option value="Monaco">Monaco</option>
                  <option value="Mongolia">Mongolia</option>
                  <option value="Montenegro">Montenegro</option>
                  <option value="Morocco">Morocco</option>
                  <option value="Mozambique">Mozambique</option>
                  <option value="Namibia">Namibia</option>
                  <option value="Nassau*">Nassau*</option>
                  <option value="Nauru">Nauru</option>
                  <option value="Nepal">Nepal</option>
                  <option value="Netherlands, The">Netherlands, The</option>
                  <option value="New Zealand">New Zealand</option>
                  <option value="Nicaragua">Nicaragua</option>
                  <option value="Niger">Niger</option>
                  <option value="Nigeria">Nigeria</option>
                  <option value="North German Confederation*">North German Confederation*</option>
                  <option value="North German Union*">North German Union*</option>
                  <option value="North Macedonia">North Macedonia</option>
                  <option value="Norway">Norway</option>
                  <option value="Oldenburg*">Oldenburg*</option>
                  <option value="Oman">Oman</option>
                  <option value="Orange Free State*">Orange Free State*</option>
                  <option value="Pakistan">Pakistan</option>
                  <option value="Palau">Palau</option>
                  <option value="Panama">Panama</option>
                  <option value="Papal States*">Papal States*</option>
                  <option value="Papua New Guinea">Papua New Guinea</option>
                  <option value="Paraguay">Paraguay</option>
                  <option value="Peru">Peru</option>
                  <option value="Philippines">Philippines</option>
                  <option value="Piedmont-Sardinia*">Piedmont-Sardinia*</option>
                  <option value="Poland">Poland</option>
                  <option value="Portugal">Portugal</option>
                  <option value="Qatar">Qatar</option>
                  <option value="Republic of Genoa*">Republic of Genoa*</option>
                  <option value="Republic of Korea (South Korea)">Republic of Korea (South Korea)</option>
                  <option value="Republic of the Congo">Republic of the Congo</option>
                  <option value="Romania">Romania</option>
                  <option value="Russia">Russia</option>
                  <option value="Rwanda">Rwanda</option>
                  <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                  <option value="Saint Lucia">Saint Lucia</option>
                  <option value="Saint Vincent and the Grenadines">Saint Vincent and the Grenadines</option>
                  <option value="Samoa">Samoa</option>
                  <option value="San Marino">San Marino</option>
                  <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                  <option value="Saudi Arabia">Saudi Arabia</option>
                  <option value="Schaumburg-Lippe*">Schaumburg-Lippe*</option>
                  <option value="Senegal">Senegal</option>
                  <option value="Serbia">Serbia</option>
                  <option value="Seychelles">Seychelles</option>
                  <option value="Sierra Leone">Sierra Leone</option>
                  <option value="Singapore">Singapore</option>
                  <option value="Slovakia">Slovakia</option>
                  <option value="Slovenia">Slovenia</option>
                  <option value="Solomon Islands, The">Solomon Islands</option>
                  <option value="Somalia">Somalia</option>
                  <option value="South Africa">South Africa</option>
                  <option value="South Sudan">South Sudan</option>
                  <option value="Spain">Spain</option>
                  <option value="Sri Lanka">Sri Lanka</option>
                  <option value="Sudan">Sudan</option>
                  <option value="Suriname">Suriname</option>
                  <option value="Sweden">Sweden</option>
                  <option value="Switzerland">Switzerland</option>
                  <option value="Syria">Syria</option>
                  <option value="Thailand">Thailand</option>
                  <option value="Timor-Leste">Timor-Leste</option>
                  <option value="Togo">Togo</option>
                  <option value="Tonga">Tonga</option>
                  <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                  <option value="Tunisia">Tunisia</option>
                  <option value="Turkey">Turkey</option>
                  <option value="Turkmenistan">Turkmenistan</option>
                  <option value="Tuvalu">Tuvalu</option>
                  <option value="Tajikistan">Tajikistan</option>
                  <option value="Tanzania">Tanzania</option>
                  <option value="United Arab Emirates, The">United Arab Emirates</option>
                  <option value="United Kingdom, The">United Kingdom</option>
                  <option value="Uruguay">Uruguay</option>
                  <option value="Uzbekistan">Uzbekistan</option>
                  <option value="Uganda">Uganda</option>
                  <option value="Ukraine">Ukraine</option>
                  <option value="Vanuatu">Vanuatu</option>
                  <option value="Venezuela">Venezuela</option>
                  <option value="Vietnam">Vietnam</option>
                  <option value="Yemen">Yemen</option>
                  <option value="Zambia">Zambia</option>
                  <option value="Zimbabwe">Zimbabwe</option>
                </select>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-4 gap-8">
            <div className="col-span-4 row-span-2">
              <div>Phone</div>
              <input className={`w-full border-2 border-gray-300 rounded p-2 focus:outline-emerald-600 mb-4`} />
            </div>
          </div>
          <div className="grid grid-cols-4 gap-8">
            <div className="col-span-4 row-span-2">
              <div>Institute Address</div>
              <textarea id="message" rows="4" class="w-full border-2 border-gray-300 rounded p-2 focus:outline-emerald-600 mb-4"></textarea>
            </div>
          </div>
          <div className="grid grid-cols-4 gap-8">
            <div className="col-span-4 row-span-2">
              <div>Title of the Submission</div>
              <input className={`w-full border-2 border-gray-300 rounded p-2 focus:outline-emerald-600 mb-4`} />
            </div>
          </div>
          <div className='mb-6'>
            <div>Article File</div>
            <input
              type="file"
              accept="application/pdf"
              className={`w-full bg-white border-2 border-gray-300 rounded  p-2 file:mr-4 file:py-2 file:px-4 file:rounded file:border-0 file:bg-emerald-600 file:text-gray-100 mb-4`}
            />
          </div>
          <div className="flex gap-4 mt-4">
            <button
              className="w-24 p-2 mb-2 text-gray-100 rounded bg-sky-600"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default SubmitArticle