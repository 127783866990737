import React, { useContext, useEffect } from "react";
import PageTitle from "./PageTitle";
import { GlobalContext } from "../context/GlobalContext";

function htmlDecode(input) {
  var e = document.createElement("textarea");
  e.innerHTML = input;
  // handle case of empty input
  return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
}

export default function ContactPage(props) {
  const { settings } = useContext(GlobalContext);
  const [hide, setHide] = React.useState(false);

  useEffect(() => {
    document.title = "Contact Us | " + settings.websitename;
  }, [settings]);

  useEffect(() => {
    const journals = ['www.botanyjournals.com', 'www.entomologyjournals.com', 'www.fishjournals.com', 'www.foodsciencejournal.com', 'www.chemicaljournals.com', 'www.chemistryjournal.in', 'www.pharmacyjournal.net', 'www.pharmacyjournal.in', 'www.biologyjournal.in']
    journals.includes(settings.domain) ? setHide(true) : setHide(false)
  }, [settings])

  return (
    <b>
      <div className="flex flex-col gap-5">
        <PageTitle title="CONTACT US" />
        {/* {hide && <div>{settings.publication}</div>} */}
        <div>{settings.publication}</div>
        <div>Head: Nikhil Gupta</div>
        <div
          dangerouslySetInnerHTML={{
            __html:
              typeof window !== "undefined" ? htmlDecode(settings.address) : "",
          }}
        ></div>
        WhatsApp your query
        <br />
        <div>

          <div className="phoneno">
            <div>Ph:</div>
            <div id="phone">{`${settings.whatsup_number}`}</div>
            <img
              className="whatsapp-icon"
              src="/assets/images/whatsapp-icon.png"
            />
          </div>
        </div>
      </div>
    </b>
  );
}
